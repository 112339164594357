import { WebStorageStateStore, Log } from 'oidc-client';

const loc = window.location;

const userManagerConfig = {
  metadata: {
    issuer: 'https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_lKKUb855R',
    authorization_endpoint: 'https://auth.viakunst-utrecht.nl/oauth2/authorize',
    token_endpoint: 'https://auth.viakunst-utrecht.nl/oauth2/token',
    userinfo_endpoint: 'https://auth.viakunst-utrecht.nl/oauth2/userInfo',
    jwks_uri: 'https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_lKKUb855R/.well-known/jwks.json',
  },
  signinKeys: [],
  authority: 'https://cognito-idp.eu-west-2.amazonaws.com/Viakunst',
  client_id: 'ag8eum3rtjbfjs2m5karnjig5',
  client_secret: '18145kfnj4c8r0ius4qncesa97s8eltgjn8m4chcivcclbf563dr',
  scope: 'openid email profile aws.cognito.signin.user.admin',
  response_type: 'code',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  redirect_uri: loc.origin.concat('/callback'),
  post_logout_redirect_uri: loc.origin.concat('/'),
};

Log.logger = console;
Log.level = Log.INFO;

export default userManagerConfig;
