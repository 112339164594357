const awsConfig = {
  region: 'eu-west-2',
};

// PRODUCTION
export default awsConfig;

export const REGION = 'eu-west-2';
export const IDENTITY_POOL_ID = 'eu-west-2:e2b6cd4a-94a5-47c5-9637-d89d06e33650';
export const USER_POOL_ID = 'eu-west-2_lKKUb855R';
export const ACCOUNT_ID = '244848962601';
