/* eslint-disable @typescript-eslint/no-unused-vars */
import LocalConfigAdapter from '../adapters/config/LocalConfigAdapter';
import UrlConfigAdapter from '../adapters/config/UrlConfigAdapter';
import { ConfigContext, AttributeTypes } from '../attributes/AttributeConfigData';

const externalAttributeConfig = new UrlConfigAdapter('https://profile.viakunst-utrecht.nl/config.json', false);

// dont forget to export your config!
export default externalAttributeConfig;

// Allowed user pools
export const localAdminConfig = {
  allowedUserpools: ['ViaKunst Accounts'],
};
